import { inject, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router, UrlTree } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular/standalone';
import { map } from 'rxjs/operators';
import { StorageService } from '../lib/services/storage.service';
import { Observable } from 'rxjs';

type ICanActivateFn = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;

@Injectable({
  providedIn: 'root',
})
export class GuardService {
  private readonly auth = inject(AuthService);
  private readonly router = inject(Router);
  private readonly storage = inject(StorageService);
  private readonly navCtrl = inject(NavController);
  private readonly alertCtrl = inject(AlertController);

  constructor() {}

  requiredUnauthorized(): ICanActivateFn {
    return this.auth.isAuth().pipe(
      map((data) => {
        if (data === 'user') {
          this.navCtrl.setDirection('root');
          this.router.navigate(['/']);
          return false;
        } else if (data === 'confirm') {
          this.router.navigate(['/auth/confirm']);
          return false;
        }
        return true;
      }),
    );
  }

  requireConfirming(): ICanActivateFn {
    return this.auth.isAuth().pipe(
      map((data) => {
        if (data !== 'confirm') {
          this.navCtrl.setDirection('root');
          this.router.navigate(['/auth/signin']);
          return false;
        }
        return data === 'confirm';
      }),
    );
  }

  requireAuthorized(): ICanActivateFn {
    return this.auth.isAuth().pipe(
      map((data) => {
        if (data === 'user') {
          return true;
        } else {
          this.navCtrl.setDirection('root');
          this.router.navigate(['auth']);
          return false;
        }
      }),
    );
  }
}
